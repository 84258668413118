.slidingVertical {
  display: inline;
  text-indent: 8px;
}
.slidingVertical span {
  animation: topToBottom 10s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
}
.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
}

@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-11px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(11px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
