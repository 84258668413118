#second-level-menu {
  position: absolute;
  top: 40px;
  left: 15px;
  display: none;
  flex-direction: column;
  z-index: 30;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(34, 37, 39, 0.2);
  border-radius: 12px;
}

#top-level-menu {
  width: 200px;
  flex-direction: column;
  display: none;
  position: absolute;
  top: 45px;
  z-index: 20;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(34, 37, 39, 0.2);
  border-radius: 12px;
}
